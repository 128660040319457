import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  DownloadBarBtn,
  DownloadBarTitle,
  HelpGifOpenExtBrowser,
  HelpGifOpenExtBrowserMp4,
  HelpGifOpenShareAddHomescreen,
  HelpGifOpenShareAddHomescreenMp4,
} from "src/assets";
import { useAppContext } from "src/main/constants";
import { useGtmTracker } from "src/main/hooks";
import { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles } from "src/main/utils";
import HelpGifDialog from "../HelpGifDialog";

interface BeforeInstallEvent {
  prompt: () => void;
  userChoice: Promise<any> | null;
}

interface InstallBannerProps extends BoxProps {}

const InstallBanner: React.FC<InstallBannerProps> = (props) => {
  const { ...rest } = props;
  const { sendEvent, sendSingularEvent } = useGtmTracker();
  const { isFbIab = false, isLineIab = false, isIosDevice = false, isFullScreen = false } = useAppContext();
  const [requestedInstall, setRequestedInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallEvent | null | undefined>();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as unknown as BeforeInstallEvent);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (requestedInstall && (isFbIab || isLineIab)) {
      sendSingularEvent(EvtAction.ShowOpenExtBrowser);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab]);

  useEffect(() => {
    if (requestedInstall && !isFbIab && !isLineIab && isIosDevice) {
      sendSingularEvent(EvtAction.ShowIosAddToHome);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab, isIosDevice]);

  const canInstall = deferredPrompt !== null && deferredPrompt !== undefined;

  if (!canInstall && !isFbIab && !isLineIab && !isIosDevice) return null;
  if (isFullScreen) return null;

  const install = () => {
    (async () => {
      deferredPrompt?.prompt();
      const { outcome } = await deferredPrompt?.userChoice;
      setDeferredPrompt(null);
      if (outcome === "accepted") {
        // success
        sendEvent(EvtAction.InstallSuccess);
      } else if (outcome === "dismissed") {
        // dismissed
      }
    })();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    sendEvent(EvtAction.ClickInstallBanner);
    if (isFbIab || isLineIab || isIosDevice) {
      setRequestedInstall(true);
    } else {
      install();
    }
  };

  const handleClose = (e) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    setRequestedInstall(false);
  };

  return (
    <Box
      sx={styles.root}
      {...rest}
      maxWidth="sm"
    >
      <Box
        sx={styles.container}
        onClick={handleClick}
      >
        <Box className="title">
          <img
            src={DownloadBarTitle}
            alt="ดาวน์โหลด Wow268.GG!"
            style={{
              height: "55px",
            }}
          />
        </Box>
        <Box className="btn">
          <img
            src={DownloadBarBtn}
            alt="ดาวน์โหลด Wow268.GG!"
            style={{
              height: "55px",
            }}
          />
        </Box>
      </Box>
      <HelpGifDialog
        title="ดาวน์โหลด Wow268.GG!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isLineIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด Wow268.GG!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isFbIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด Wow268.GG!"
        src={HelpGifOpenShareAddHomescreen}
        fallbackSrc={HelpGifOpenShareAddHomescreenMp4}
        open={requestedInstall && !isFbIab && !isLineIab && isIosDevice}
        onClose={(event) => handleClose(event)}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    width: "100%",
    height: "71px",
    margin: "auto",
  },
  container: {
    width: "inherit",
    height: "inherit",
    zIndex: 15,
    padding: "0 8px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    // backgroundImage: `url(${DownloadBarBG})`,
    background: `-webkit-linear-gradient( 90deg, rgb(230,204,120) 11%,
rgb(216,184,98) 18%, rgb(202,164,76) 40%, rgb(228,215,154) 76%)`,

    ".title": {
      height: "100%",
      flexShrink: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".btn": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      backgroundSize: "contain",
      flexShrink: 0,
    },
  },
});

export default InstallBanner;

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { PropsWithChildren, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DebugDialog } from "./main/components";
import { AppContext, Paths } from "./main/constants";
import { useUrls } from "./main/hooks";
import { createTheme } from "./main/theme";
import { Landing } from "./main/views";

export interface AppProps extends PropsWithChildren {

}

const detectFbIab = () => {
  if (typeof navigator.userAgent !== "string") return false;
  return Boolean(navigator.userAgent.match(/FBAN|FBAV/i));
}
const detectLineIab = () => {
  return Boolean(navigator.userAgent.match(/Line\/[\d.]+\/IAB/i))
}
const detectIosDevice = () => {
  return [
    "iPad Simulator", "iPhone Simulator", "iPod Simulator",
    "iPad", "iPhone", "iPod"
  ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

const App: React.FC<AppProps> = (props) => {
  const theme = createTheme({ mode: "light" });
  const urls = useUrls();

  const appContextData = useMemo(() => {
    let isFbIab = detectFbIab(), isLineIab = detectLineIab(), isIosDevice = false, isFullScreen = false;
    if (typeof navigator.platform === "string" && typeof navigator.userAgent === "string") {
      isIosDevice = detectIosDevice();
      isFullScreen = 'standalone' in navigator && !!navigator.standalone && (/iphone|ipod|ipad/gi).test(navigator.platform) && (/Safari/i).test(navigator.appVersion);
    }
    return { urls, isFbIab, isLineIab, isIosDevice, isFullScreen };
  }, [urls]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={appContextData}>
        <CssBaseline />
        <Routes>
          <Route path={`${Paths.Landing.Index}/*`} element={<Landing.Main />} />

          <Route path="*" element={<Navigate to={Paths.Landing.Index} replace />} />
        </Routes>
        <DebugDialog />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
